<template>
  <div>
      <b-row>
      <b-col lg="12" md="12" sm="12" class="heading-title">
     
						<h4>Parent Venue Edit</h4>
					
						<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item to="/venues">Parent Venue</b-breadcrumb-item>
					<b-breadcrumb-item active>Edit</b-breadcrumb-item>
				</b-breadcrumb>
				
       
      </b-col>
    <!--   <b-col lg="6" md="6" sm="6" class="btns-header"> </b-col> -->
    </b-row>
    <br>
    <b-cards class="mb-4 w-100">
      <b-form @submit.prevent="handleSubmit">
        <h6 class="custom-text"><span>Venue Details</span></h6>
           <b-row>
          <!-- part 1(1) -->
          <b-col md="6">
            <b-row class="py-3 align-items-center">
              <b-col sm="4" class="text-left">
                <label class="fw-medium mb-0" for="lname2">Company Name<span class="text-danger">*</span> :</label>
              </b-col>
              <b-col sm="8">
                <b-form-group> 
                  <b-form-input  class="venue-custom-input"
                    id="c_name"
                    type="text"
                    placeholder="Enter your Company Name"
                    v-model="$v.form.c_name.$model"
                    :state="validateState('c_name')"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="pehrs-1-live-feedback"
                    v-if="$v.form.c_name.$error"
                  >
                    <div v-if="!$v.form.c_name.required">
                      Please enter your Company Name
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row class="py-3 align-items-center">
              <b-col sm="4" class="text-left">
                <label class="fw-medium mb-0" for="nname2">Venue Name<span class="text-danger">*</span> :</label>
              </b-col>
              <b-col sm="8">
                <b-form-group>
                  <b-form-input  class="venue-custom-input"
                    id="v_name"
                    type="text"
                    placeholder="Enter your Venue Name"
                    v-model="$v.form.v_name.$model"
                    :state="validateState('v_name')"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="pehrs-1-live-feedback"
                    v-if="$v.form.v_name.$error"
                  >
                    <div v-if="!$v.form.v_name.required">
                      Please enter your Venue Name
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-row class="py-3 align-items-center">
              <b-col sm="4" class="text-left">
                <label class="fw-medium mb-0" for="uname2"> Pincode<span class="text-danger">*</span> :</label>
              </b-col>
              <b-col sm="8">
                <b-form-group>
                  <b-form-input class="venue-custom-input"
                    id="pincode"
                    type="text"
                    placeholder="Enter your Pincode"
                    v-model="$v.form.pincode.$model"
                    :state="validateState('pincode')"
                    @keyup="pincodeCheck()"
                  ></b-form-input>
                  <b-form-invalid-feedback id="pehrs-1-live-feedback">
                    <div v-if="!$v.form.pincode.required">
                      Please enter your pincode
                    </div>
                    <div
                      v-if="
                        !$v.form.pincode.minLength ||
                        !$v.form.pincode.numeric ||
                        !$v.form.pincode.maxLength
                      "
                    >
                      Please enter valid pincode
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row class="py-3 align-items-center">
              <b-col sm="4" class="text-left">
                <label class="fw-medium mb-0" for="lname2">City<span class="text-danger">*</span> :</label>
              </b-col>
              <b-col sm="8">
                <b-form-group>
                  <b-form-input class="venue-custom-input"
                    name="city"
                    type="text"
                    placeholder="Enter your City"
                    v-model="$v.form.city.$model"
                    :state="validateState('city')"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="pehrs-1-live-feedback"
                    v-if="$v.form.city.$error"
                  >
                    <div v-if="!$v.form.city.required">
                      Please enter your city
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col md="6">
            <b-row class="py-3 align-items-center">
              <b-col sm="4" class="text-left">
                <label class="fw-medium mb-0" for="uname2">State<span class="text-danger">*</span> :</label>
              </b-col>
              <b-col sm="8">
                <b-form-group>
                  <b-form-input class="venue-custom-input"
                    id="state"
                    type="text"
                    placeholder="Enter your State"
                    v-model="$v.form.state.$model"
                    :state="validateState('state')"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="pehrs-1-live-feedback"
                    v-if="$v.form.state.$error"
                  >
                    <div v-if="!$v.form.state.required">
                      Please enter your state
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row class="py-3 align-items-center">
              <b-col sm="4" class="text-left">
                <label class="fw-medium mb-0" for="nname2">Country<span class="text-danger">*</span> :</label>
              </b-col>
              <b-col sm="8">
                <b-form-group>
                  <b-form-input class="venue-custom-input"
                    name="country"
                    type="text"
                    placeholder="Enter your Country"
                    v-model="$v.form.country.$model"
                    :state="validateState('country')"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="pehrs-1-live-feedback"
                    v-if="$v.form.country.$error"
                  >
                    <div v-if="!$v.form.country.required">
                      Please enter your country
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          
          <b-col md="6">
            <b-row class="py-3 align-items-center">
              <b-col sm="4" class="text-left">
                <label class="fw-medium mb-0" for="uname2">Address<span class="text-danger">*</span> :</label>
              </b-col>
              <b-col sm="8">
                <b-form-group>
                  <b-form-textarea class="venue-custom-input"
                    id="address"
                    type="text"
                    placeholder="Enter your Address"
                    v-model="$v.form.address.$model"
                    :state="validateState('address')"
                  ></b-form-textarea>
                  <b-form-invalid-feedback
                    id="pehrs-1-live-feedback"
                    v-if="$v.form.address.$error"
                  >
                    <div v-if="!$v.form.address.required">
                      Please enter your address
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
               <b-row class="py-3 align-items-center">
              <b-col sm="3" class="text-left">
                <label class="fw-medium mb-0" for="lname2">Show on map:</label>
              </b-col>
              <b-col sm="9">
                <b-form-group>
                  <b-form-checkbox
                    id="checkbox-1"
                    name="checkbox-1"
                    value="1"
                    unchecked-value="0"
                    v-model="form.show_map"
                  
                  >
                  </b-form-checkbox>
              
                </b-form-group>
              </b-col>
            </b-row>
            </b-col>
        </b-row>
           <h6 class="custom-text"><span>Social media Details</span></h6>
           <b-row>
          <b-col md="6">
            <b-row class="py-3 align-items-center">
              <b-col sm="4" class="text-left">
                <label class="fw-medium mb-0" for="uname2">
                  Facebook Url:</label
                >
              </b-col>
              <b-col sm="8">
                <b-form-group>
                  <b-form-input class="venue-custom-input"
                    id="fb"
                    type="text"
                    placeholder="Enter your Facebook url"
                    v-model="$v.form.facebook_url.$model"
                    :state="validateState('facebook_url')"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="pehrs-1-live-feedback"
                    v-if="$v.form.facebook_url.$error"
                  >
                    Please enter valid url
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row class="py-3 align-items-center">
              <b-col sm="4" class="text-left">
                <label class="fw-medium mb-0" for="uname2">
                  Instagram Url:</label
                >
              </b-col>
              <b-col sm="8">
                <b-form-group>
                  <b-form-input class="venue-custom-input"
                    id="insta"
                    type="text"
                    placeholder="Enter your Instagram url"
                    v-model="$v.form.instagram_url.$model"
                    :state="validateState('instagram_url')"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="pehrs-1-live-feedback"
                    v-if="$v.form.instagram_url.$error"
                  >
                    Please enter valid url
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-row class="py-3 align-items-center">
              <b-col sm="4" class="text-left">
                <label class="fw-medium mb-0" for="uname2"> Youtube Url:</label>
              </b-col>
              <b-col sm="8">
                <b-form-group>
                  <b-form-input class="venue-custom-input"
                    id="yb"
                    type="text"
                    placeholder="Enter your Youtube url"
                    v-model="$v.form.youtube_url.$model"
                    :state="validateState('youtube_url')"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="pehrs-1-live-feedback"
                    v-if="$v.form.youtube_url.$error"
                  >
                    Please enter valid url
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row class="py-3 align-items-center">
              <b-col sm="4" class="text-left">
                <label class="fw-medium mb-0" for="uname2"> Twitter Url:</label>
              </b-col>
              <b-col sm="8">
                <b-form-group>
                  <b-form-input class="venue-custom-input"
                    id="tw"
                    type="text"
                    placeholder="Enter your Twitter url"
                    v-model="$v.form.twitter_url.$model"
                    :state="validateState('twitter_url')"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="pehrs-1-live-feedback"
                    v-if="$v.form.twitter_url.$error"
                  >
                    Please enter valid url
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row class="py-3 align-items-center">
              <b-col sm="4" class="text-left">
                <label class="fw-medium mb-0" for="uname2"> Website Url:</label>
              </b-col>
              <b-col sm="8">
                <b-form-group>
                  <b-form-input class="venue-custom-input"
                    id="tw"
                    type="text"
                    placeholder="Enter your Website url"
                    v-model="$v.form.website_url.$model"
                    :state="validateState('website_url')"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="pehrs-1-live-feedback"
                    v-if="$v.form.website_url.$error"
                  >
                    Please enter valid url
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div class="text-center">
          <b-button to="/venues"  class="mt-2 btn-venuecolor">
            Back
          </b-button>
          &nbsp;
          <b-button type="submit"  class="mt-2 btn-venuecolor">
             Update
          </b-button>
        </div>
      </b-form>
    </b-cards>
  </div>
</template>
<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  numeric,helpers,url
} from "vuelidate/lib/validators";
const facebookCheck = helpers.regex(
  "facebookCheck",
  /^(https?:\/\/){0,1}(www\.){0,1}facebook\.com/
);
const instagramCheck = helpers.regex(
  "instagramCheck",
  /^(https?:\/\/){0,1}(www\.){0,1}instagram\.com/
);
const youtubeCheck = helpers.regex(
  "youtubeCheck",
  /^(https?:\/\/){0,1}(www\.){0,1}youtube\.com/
);
const twitterCheck = helpers.regex(
  "twitterCheck",
  /^(https?:\/\/){0,1}(www\.){0,1}twitter\.com/
);
export default {
  name: "parent_venue_edit",
  mixins: [validationMixin],
  data: () => ({
    title: "parent_venue_edit",
    form: {
      c_name: "",
      v_name: "",
      address: "",
      state: "",
      city: "",
      country: "",
      show_map: "",
      pincode: "",
      facebook_url: "",
      instagram_url: "",
      youtube_url: "",
      twitter_url: "",
    },
    
  }),
  components: {},
  validations: {
    form: {
      c_name: {
        required,
      },
      v_name: {
        required,
      },
      address: {
        required,
      },
      state: {
        required,
      },
      city: {
        required,
      },
      country: {
        required,
      },
      pincode: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6),
        numeric,
      },
       facebook_url: {
        facebookCheck,
      },
      instagram_url: {
        instagramCheck,
      },
      youtube_url: {
        youtubeCheck,
      },
      twitter_url: {
        twitterCheck,
      }, website_url: {
        url,
      },
    },
    venue_id: "",
  },
  methods: {
   
    validateState(c_name) {
      const { $dirty, $error } = this.$v.form[c_name];
      return $dirty ? !$error : null;
    },
    getData() {
      this.venue_id = this.$route.params.id;

      axios
        .post("/api/edit_parent_venue", {
          venue_id: this.venue_id,
        })
        .then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status) {
              this.venue_id = resp.data.parent_venue.parent_venue_id;
              this.form.v_name = resp.data.parent_venue.venue_name;
              this.form.c_name = resp.data.parent_venue.venue_company_name;
              this.form.address = resp.data.parent_venue.venue_address;
              this.form.country = resp.data.parent_venue.venue_country;
              this.form.state = resp.data.parent_venue.venue_state;
              this.form.city = resp.data.parent_venue.venue_city;
              this.form.pincode = resp.data.parent_venue.venue_pincode;
              this.form.show_map = resp.data.parent_venue.show_map;
              this.form.facebook_url = resp.data.parent_venue.facebook_url,
              this.form.instagram_url = resp.data.parent_venue.instagram_url,
              this.form.youtube_url = resp.data.parent_venue.youtube_url,
              this.form.twitter_url = resp.data.parent_venue.twitter_url
              this.form.website_url = resp.data.parent_venue.website_url
  
            } else {
              this.$router.push("/venues");
            }
          }
        });
    },
    handleSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
       
        axios
          .post("/api/update_parent_venue", {
            venue_id: this.venue_id,
            venue_name: this.form.v_name,
            venue_company_name: this.form.c_name,
            venue_country: this.form.country,
            venue_state: this.form.state,
            venue_city: this.form.city,
            venue_address: this.form.address,
            venue_pincode: this.form.pincode,
            show_map: this.form.show_map,
            facebook_url : this.form.facebook_url,
            instagram_url : this.form.instagram_url,
            youtube_url : this.form.youtube_url,
            twitter_url : this.form.twitter_url,
            website_url : this.form.website_url,
          })
          .then((resp) => {
            if (resp.data.status) {
              this.$v.form.$reset();
              this.$router.push("/venues", () => {
                this.$root.$refs.app.showToast(
                  "success",
                  resp.data.message
                );
              });
            } else {
              this.$root.$refs.app.showToast(
                "danger",
                resp.data.message
              );
            }
          });
      }
    },
    pincodeCheck()
      {
        const str=this.form.pincode;
        axios
                .post("/api/pincodeCheck", {
                    pincode: str
				})
                .then((resp) => {
                    if (resp.data.pincode_city==0) {
                      this.form.city="";
                      this.form.state="";
                      this.form.country="";
                    }
                    else
                    {
                      this.form.city=resp.data.pincode_city.division;
                      this.form.state=resp.data.pincode_city.state;
                      this.form.country=resp.data.pincode_city.country;
                     
                    }
				});
      }
  },
  mounted() {
    this.getData();
  },
};
</script>
<style scoped>

.form-group {
  margin: 0;
}
@media (max-width: 640px) {
}

</style>